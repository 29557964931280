.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 413px;
    height: 490px;
    background: #F9F9FE;
    border-radius: 8px;
}

.form h1 {
    margin: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #2D2E46;
}

.gap {
    height: 400px;
    margin: 80px
}

.form a {
    width: 101px;
    height: 17px;
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #2D2E46;
    text-decoration: none;
}