.nav_ex2 {
    margin-top: 1rem;
    display: flex;
}

.navButton {
    width: 24px;
    height: 24px;
    margin: 5px;
    border: none;
    background: none;
    border-radius: 3px;
}

.navButton_focus {
    width: 24px;
    height: 24px;
    margin: 5px;
    border: none;
    border-radius: 3px;
    background-color: #21268F;
    color: white;
}

.pagination {
}

.selector {
    display: flex;
    align-items: center;
    margin: 5px;
}

.selector h5 {
    font-weight: normal;
    margin: 0 5px 0 5px;
}