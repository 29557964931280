.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal_content {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    transform: scale(0.5);
    transition: 0.4s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal_content.active {
    transform: scale(1);
}