.cardWrapper {
    width: 1008px;
    height: 636px;
    background-color: white;
    border-radius: 5px;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.head {
    display: flex;
    align-items: center;
}

.head button {
    width: 36px;
    height: 33px;
    margin-right: 14px;
}

.head h1 {
    width: 127px;
    height: 33px;
    font-size: 22px;
    line-height: 33px;
    color: #2D2E46;
}

.cardWrapper input {
    width: 756px;
    height: 36px;
    background: #ECECF9;
    opacity: 0.5;
    border: 1px solid #635D80;
    border-radius: 2px;
    margin: 20px 20px 24px 0px;
    padding: 7px;
}

.main button {
    width: 184px;
    height: 36px;
    background: #21268F;
    border-radius: 30px;
    color: white;
}

.cardHead {
    width: 960px;
    height: 48px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #ECECF9;
}

.cardWrapper table {
    box-shadow: 0 0 10px 1px lightgray;
}



