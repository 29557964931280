.showPacksCards {
    width: 243px;
    height: 615px;
    background: #ECECF9;
    border-radius: 8px 0 0 8px;
    padding: 15px;
}

.showPacksCards h6 {
    font-size: 20px;
    color: #2D2E46;
    margin-bottom: 22px;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inactiveButton {
    width: 99px;
    height: 36px;
    color: white;
    background: #21268F;
    border: none;
    border-radius: 3px;
}

.inactiveButton:hover {
    width: 99px;
    height: 36px;
    color: white;
    background: #2d53cb;
}

.activeButton {
    width: 99px;
    height: 36px;
    background: white;
    border: none;
    border-radius: 3px;
}

.activeButton:hover {
    width: 99px;
    height: 36px;
    background: whitesmoke;
}






