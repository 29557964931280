.wrapper {
    width: 1008px;
    height: 615px;
    background: #FEFEFF;
    border-radius: 8px;
    display: flex;
}

.showPacksCards {
    width: 243px;
    height: 615px;
    background: #ECECF9;
    border-radius: 8px 0 0 8px;
    padding: 15px;
}

.showPacksCards h6 {
    font-size: 20px;
    color: #2D2E46;
    margin-bottom: 22px;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.showPacksCards button {
    width: 99px;
    height: 36px;
    color: white;
    background: #21268F;
}

.showPacksCards button:hover {
    width: 99px;
    height: 36px;
    color: white;
    background: #2d53cb;
}

.range {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.packs {
    width: 765px;
    height: 615px;
    background: #FEFEFF;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packs h1 {
    width: 104px;
    height: 33px;
    font-size: 22px;
    color: #2D2E46;
    margin-bottom: 15px;
}

.input {
    width: 461px;
    height: 36px;
    opacity: 50%;
    box-sizing: border-box;
    background: #ECECF9;
    border: 1px solid #635D80;
    border-radius: 2px;
    margin-bottom: 24px;
    padding: 5px
}

.button {
    width: 184px;
    height: 36px;
    color: white;
    background: #21268F;
    border-radius: 30px;
    margin-left: 24px;
}
.button:hover {
    width: 184px;
    height: 36px;
    color: white;
    background: #2d53cb;
    border-radius: 30px;
    margin-left: 24px;
}

.cards {
    width: 669px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #ECECF9;
}

.cards th {
    width: 100px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.wrapper table {
    box-shadow: 0 0 10px 1px lightgray;
}

.selectors {
    display: flex;
}






