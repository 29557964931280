.header {
    width: 100%;
    height: 60px;
    margin-bottom: 15px;
        background: #EBE0E9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header a {
    Width: 173px;
    Height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
}

.header a:hover {
    Width: 173px;
    Height: 60px;
    background: #DCCCDB;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.active {

}